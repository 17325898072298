import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { DELETE_ASSESSMENT, FETCH_ASSESSMENT_TITLES } from 'query';
import { getItem } from 'utils';

import {
  DeletePromptModal,
  EventsActionContainer,
  EventsPopupAction,
  Search,
  SpinnerComponent,
} from 'components';

import { AddNew, Empty } from './component';

const styles = {
  border: 'none',
  background: '#F7F9FB',
  paddingLeft: '0',
};

const isTesting = process.env.NODE_ENV === 'test';

const AssessmentView = () => {
  const [page, setPage] = React.useState(1);
  const [show, setShow] = React.useState(false);
  const [assId, setID] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [searchString, setSearchString] = React.useState('');
  const user = getItem('hrm_user');
  let navigate = useNavigate();

  const [removeAssessment, { loading: deleting }] = useMutation(DELETE_ASSESSMENT);

  const { data, loading, refetch } = useQuery(FETCH_ASSESSMENT_TITLES, {
    variables: {
      filter: { keyword: searchString },
      page: page,
    },
  });

  const assessments = isTesting
    ? [
        {
          _id: { id: 'e923f829fhnwfw4', title: 'Assessment one' },
        },
        {
          _id: { id: 'e109f82ds843494', title: 'Assessment two' },
        },
      ]
    : data?.fetchTitles?.data;

  const { errorToast, successToast } = useToastify();

  const actions = {
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
  };

  async function deleteAssessment() {
    try {
      const res = await removeAssessment({
        variables: { id: assId },
      });
      const message = res?.data?.deleteAssessment?.message;
      successToast(message);
      refetch();
    } catch (error) {
      errorToast('Error Occured');
    } finally {
      setShow(false);
    }
  }

  function performAction({ action, id, title }) {
    switch (action) {
      case 'view':
        navigate(`/admin/assessments/preview/${title}`);
        break;
      case 'edit':
        navigate(`/admin/assessments/add`, { state: { title: title, isEditing: true } });
        break;
      case 'delete':
        setID(id);
        setShow(true);
        break;
    }
  }

  const handleSearchChange = (val) => {
    setSearch(val);
  };
  return (
    <AssessmentRoot data-testid="admin-assessment">
      <div className="header">
        <p className="title">ASSESSMENTS</p>
        <p className="grey">{`Hello ${user?.firstName}. Welcome to your ${user?.companyId?.companyName} workspace`}</p>
      </div>

      <div className="content">
        {assessments?.length === 0 && !loading && searchString === '' ? (
          <Empty
            addNew={() =>
              navigate('/admin/assessments/add', { state: { title: '', isEditing: false } })
            }
          />
        ) : (
          <div className="not__empty">
            <Search
              style={{ ...styles }}
              placeholder="Search"
              removeIcon={true}
              value={search}
              setPage={setPage}
              setSearchString={setSearchString}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            {loading && !isTesting ? (
              <SpinnerComponent />
            ) : (
              <div className="assessment__list">
                {assessments?.map((el) => {
                  return (
                    <Box
                      cursor={'pointer'}
                      key={el?._id?.id}
                      onClick={() => navigate(`/admin/assessments/preview/${el?._id?.title}`)}>
                      <div className="assessment__list--item">
                        <div className="assessment__list--item-card">
                          <img
                            src={`https://api.dicebear.com/7.x/initials/svg?seed=${el?._id?.title.trim()}&backgroundColor=3e7eee&textColor=ffffff&fontFamily=Helvetica`}
                            alt=""
                          />
                        </div>
                        <Flex justifyContent={'space-between'} alignItems={'center'} mt="10px">
                          <div className="p-wrap">
                            <p className="name">{el?._id?.title}</p>
                          </div>
                          <EventsActionContainer onClick={(e) => e.stopPropagation()}>
                            <EventsPopupAction
                              dropdownActions={actions}
                              id={el?._id?.id}
                              actionOnData={({ action, id }) =>
                                performAction({ action, id, title: el?._id?.title })
                              }
                            />
                          </EventsActionContainer>
                        </Flex>
                      </div>
                    </Box>
                  );
                })}
                <AddNew />
              </div>
            )}
          </div>
        )}
      </div>
      {show && (
        <DeletePromptModal
          show={show}
          cancel={() => setShow(false)}
          deleteItem={deleteAssessment}
          loading={deleting}
        />
      )}
    </AssessmentRoot>
  );
};

const AssessmentRoot = styled.div`
  padding: 0 2.4rem 3.2rem;
  height: auto;
  min-height: calc(100vh - 6rem);

  .grey {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #999fa3;
  }

  .header {
    position: sticky;
    top: 6rem;
    display: grid;
    gap: 0.8rem;
    margin-bottom: 4rem;
    background: #fafcff;
    z-index: 15;
    padding: 4.8rem 0 2.4rem;
    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: #23273b;
    }
  }

  .content {
    width: 100%;
    height: auto;

    .not__empty {
      margin-top: 3.2rem;

      .assessment__list {
        margin-top: 3.2rem;
        display: flex;
        flex-wrap: wrap;
        gap: 4.8rem;
        &--item {
          &-card {
            width: 180px;
            height: 180px;
            background: white;
            //background: #3e7eee;
            //background: conic-gradient(from 180deg at 50% 50%, #3e7eee 0deg, #36ca8b 360deg);
            border: 1px solid #3e7eee;
            border-radius: 8px;
            //opacity: 0.15;
            z-index: 5;
            display: grid;
            place-content: center;

            img {
              position: relative;
              width: 80px;
              height: 80px;
              border-radius: 8px;

              //&:before {
              //  content: '';
              //  position: absolute;
              //  z-index: 10;
              //  top: -48px;
              //}
            }
          }
          .p-wrap .name {
            width: 140px;
            padding: 0;
            overflow: hidden;
            position: relative;
            display: inline-block;
            margin: 0 5px 0 5px;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
        }
      }
    }
  }
`;

export { AssessmentView };
