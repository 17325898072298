import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import Calendar from 'assets/icons/dateCalendar.svg';
import { useEmployeeOptions } from 'context';
import { FETCH_ALL_TEAMS } from 'query';
import { inputError } from 'utils';

import {
  BaseDropdown,
  BaseInput,
  CheckboxWrapper,
  CurrencyInput,
  CustomCheckbox,
} from '../../../../components';
import { compensationOptions, employmentType } from '../../../../data/dropDownOptions';

const colSpan = { base: 2, md: 1 };

export const EmployementInformation = ({
  register,
  control,
  getCurrencyCode,
  currencyCode,
  benefits,
  isEdit,
  errors,
}) => {
  const watchDepartment = useWatch({ control, name: 'department' });
  const { getOption } = useEmployeeOptions({ department: watchDepartment });
  const { data } = useQuery(FETCH_ALL_TEAMS);

  const teamDropDown = data?.fetchTeams?.data.map((item) => {
    return {
      label: item.name,
      value: item._id,
    };
  });

  return (
    <Box marginTop={'4rem'} data-testid="employment-details">
      <SimpleGrid columns={2} gap="6">
        <GridItem colSpan={colSpan}>
          <FormControl isRequired>
            <FormLabel fontSize="1.4rem" fontWeight="400">
              Date Hired
            </FormLabel>
            <BaseInput
              {...register('employmentInfo.hireDate')}
              fontSize="1.6rem"
              type="date"
              right={'1rem'}
              color={'rgba(0,0,0,0.5)'}
              rightIcon={Calendar}
            />
            {inputError(errors, 'employmentInfo.hireDate')}
          </FormControl>
        </GridItem>
        <GridItem colSpan={colSpan}>
          <FormControl isRequired>
            <FormLabel fontSize="1.4rem" fontWeight="400">
              Employment Type
            </FormLabel>
            <Controller
              defaultValue=""
              control={control}
              name="employmentInfo.employmentType"
              render={({ field }) => (
                <BaseDropdown
                  {...field}
                  options={employmentType}
                  w="322"
                  labelSize="1.4rem"
                  border="1px solid #E0E0E0"
                />
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={colSpan}>
          <FormControl isRequired>
            <FormLabel fontSize="1.4rem" fontWeight="400">
              ID Number
            </FormLabel>
            <BaseInput {...register('staffId')} placeholder="ENY/001" fontSize="1.6rem" />
            {inputError(errors, 'staffId')}
          </FormControl>
        </GridItem>
        <GridItem colSpan={colSpan}>
          <Controller
            defaultValue=""
            control={control}
            name="department"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                label="Department"
                labelSize="1.4rem"
                border="1px solid #E0E0E0"
                options={getOption('department')}
                isRequired
              />
            )}
          />
          {inputError(errors, 'department')}
        </GridItem>
        <GridItem colSpan={colSpan} mb="1rem">
          <Controller
            defaultValue=""
            control={control}
            name="designation"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                options={getOption('designation')}
                w="322"
                label="Designation"
                labelSize="1.4rem"
                border="1px solid #E0E0E0"
                isRequired
              />
            )}
          />
          {inputError(errors, 'designation')}
        </GridItem>
        <GridItem colSpan={colSpan}>
          <Controller
            defaultValue=""
            control={control}
            name="team"
            render={({ field: { onChange, value } }) => (
              <BaseDropdown
                options={teamDropDown}
                onChange={(e) =>
                  onChange({
                    target: {
                      name: 'team',
                      value: e.value,
                    },
                  })
                }
                value={value}
                w="322"
                label="Teams"
                labelSize="1.4rem"
                border="1px solid #E0E0E0"
                isRequired
              />
            )}
          />
          {inputError(errors, 'team')}
        </GridItem>
        <GridItem colSpan={colSpan}>
          <Controller
            defaultValue=""
            control={control}
            name="gradeLevel"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                label="Authority / Grade / Level"
                labelSize="1.4rem"
                border="1px solid #E0E0E0"
                placeholder="Choose level"
                fontWeight="400"
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <Controller
            defaultValue=""
            control={control}
            name="employmentInfo.compensationTypes[0]"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                labelSize="1.4rem"
                label="Compensation Type"
                border="1px solid #E0E0E0"
                placeholder="Choose level"
                fontWeight="400"
                options={compensationOptions}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          {isEdit ? (
            <BaseInput
              {...register('employmentInfo.monthlyPayRate')}
              fontSize="1.6rem"
              label="Pay Rate"
              mt="0.1rem"
            />
          ) : (
            <FormControl>
              <Flex alignContent="center" gap="4">
                <CurrencyInput
                  label="Pay Rate"
                  getCurrencyCode={getCurrencyCode}
                  currencyCode={currencyCode}
                />
                <Input
                  {...register('employmentInfo.monthlyPayRate')}
                  h="4.8rem"
                  mt="2.5rem"
                  type="number"
                  fontSize="1.6rem"
                  backgroundColor="#F7F9FB"
                />
              </Flex>
              {inputError(errors, 'employmentInfo.monthlyPayRate')}
            </FormControl>
          )}
        </GridItem>
        <GridItem colSpan={colSpan}>
          <BaseInput
            {...register('employmentInfo.workEmail')}
            fontSize="1.6rem"
            label="Work Email"
            placeholder="example@gmail.com"
            isRequired
          />
          {inputError(errors, 'employmentInfo.workEmail')}
        </GridItem>
        <GridItem>
          <Box>
            <Text fontSize="15px">Select employee benefits</Text>
            <Grid gridTemplateColumns={'1fr 1fr'} marginTop="2rem" gap={'1.2rem'}>
              {benefits?.map((field, index) => (
                <CheckboxWrapper key={field}>
                  <CustomCheckbox
                    {...register(`employmentInfo.employeeBenefits.${index}`)}
                    variant="blue-checkbox"
                    label={field}
                    value={field}
                  />
                </CheckboxWrapper>
              ))}
            </Grid>
          </Box>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
