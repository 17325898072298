import React from 'react';

import stringHelpers from '../../../../utils/helpers/string-helpers';
import { EventsActionContainer, EventsPopupAction } from '../../../shared';
import { UpcomingCardItem, UpcomingCardItemContainer } from '../styles';

export const UpcomingEventCardItem = ({
  user,
  eventType,
  pic,
  month,
  day,
  dropdownActions,
  setShowWishModal,
  setShowBroadcastModal,
  setEmployeeDetails,
}) => {
  const actionOnData = ({ action }) => {
    switch (action) {
      case 'send-wishes':
        setShowWishModal(true);
        setEmployeeDetails(user);
        break;
      case 'broadcast-to-others':
        setEmployeeDetails(user);
        setShowBroadcastModal(true);
        break;
    }
  };

  const username = `${user?.firstName} ${user?.lastName}`;

  return (
    <UpcomingCardItemContainer data-testid="upcoming-card-item">
      <UpcomingCardItem>
        <div className="card-details">
          <div className="first-text">
            <p className="card-text">{month}</p>
            <p className="card-text ml">{`${day < 10 ? '0' + day : day}`}</p>
          </div>
          <div className="card-dots">
            <img src={pic} alt="profile of applicant" />
          </div>
          <div>
            <p className="card-text">{stringHelpers.titleCase(username)}</p>
            <p className="card-span">{eventType}</p>
          </div>
        </div>
        <EventsActionContainer data-testid="action-container">
          <EventsPopupAction dropdownActions={dropdownActions} actionOnData={actionOnData} />
        </EventsActionContainer>
      </UpcomingCardItem>
    </UpcomingCardItemContainer>
  );
};
