import React from 'react';
import { Box, Grid, Image } from '@chakra-ui/react';
import { EmployeeEditFormProvider } from 'context/employeeDetailsForm';

import {
  BankInformation,
  ContactInformation,
  EmploymentInfo,
  KinInformation,
  MedicalInformation,
  PensionInformation,
  ProfileInfo,
  UpcomingEmployeeEvent,
} from '../../../components';

import { LowerPanel, TopPanel } from './styles';

export const EmployeeViewForm = ({ employeeData }) => {
  return (
    <EmployeeEditFormProvider employeeData={employeeData}>
      <TopPanel className="top__panel">
        <div className="profile__pic">
          <Image
            width="12.5rem"
            height="12.5rem"
            src={
              employeeData?.imageUrl ||
              `https://api.dicebear.com/7.x/initials/svg?seed=${employeeData?.firstName} ${employeeData?.lastName}`
            }
            alt="profile pic"
          />
        </div>
        <Grid className="profile__info">
          <ProfileInfo />
        </Grid>
      </TopPanel>
      <Box mt={'2.4rem'}>
        <UpcomingEmployeeEvent />
      </Box>
      <LowerPanel className="lower__panel">
        <Box className="left__column">
          <EmploymentInfo />
          <ContactInformation />
        </Box>
        <Box className="right__column">
          <KinInformation />
          <PensionInformation />
          <BankInformation />
          <MedicalInformation />
        </Box>
      </LowerPanel>
    </EmployeeEditFormProvider>
  );
};
