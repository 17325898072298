import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex, Grid, Heading, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { RecordPrompt } from 'pages/employee/dashboard/training/component';
import { SHARED_TRAINING } from 'query';

import {
  ActionDropdown,
  DeletePromptModal,
  EmptyState,
  FullModal,
  ModalBackButton,
  SimpleModal,
} from 'components';

import deleteIcon from '../../../../assets/icons/Delete.svg';
import edit from '../../../../assets/icons/Edit.svg';

import notificationSvg from './images/Notification.svg';
import recordSvg from './images/record.svg';

import { css } from '@emotion/css';
export function ViewTrainingDetails(props) {
  const [show, setShow] = useState(false);
  const [showRecord, setShowRecord] = useState(false);

  const { data: ViewTrainingDetails, refetch } = useQuery(SHARED_TRAINING, {
    variables: {
      filter: {
        _id: props?.id,
      },
    },
  });

  const name = ViewTrainingDetails?.fetchTraining?.data[0]?.name;
  const attachedFile = ViewTrainingDetails?.fetchTraining?.data[0]?.attachedFile;
  const description = ViewTrainingDetails?.fetchTraining?.data[0]?.description;
  const dueDate = ViewTrainingDetails?.fetchTraining?.data[0]?.dueDate;
  const status = ViewTrainingDetails?.fetchTraining?.data[0]?.status;
  const individualTrainee = ViewTrainingDetails?.fetchTraining?.data[0]?.employees;
  const details = ViewTrainingDetails?.fetchTraining?.data[0]?.details?.join(', ');
  const repeatProgram = ViewTrainingDetails?.fetchTraining?.data[0]?.repeatProgram;
  const dropDown = [
    {
      label: 'Edit Details',
      value: 'edit',
      src: edit,
    },
    {
      label: 'Delete',
      value: 'delete',
      src: deleteIcon,
      color: 'red',
    },
  ];
  const dropDownForTable = [
    {
      label: 'View Details',
      value: 'view',
      src: notificationSvg,
    },
    {
      label: 'Remind',
      value: 'remind',
      src: notificationSvg,
    },
    {
      label: 'Record Completion',
      value: 'record',
      src: recordSvg,
    },
    {
      label: 'Delete',
      value: 'delete',
      src: deleteIcon,
      color: 'red',
    },
  ];
  const actionPerform = (action, id) => {
    switch (action?.value) {
      case 'edit':
        props.setShowModal(false);
        props?.onEdit(id);
        break;
      case 'record':
        setShowRecord(true);
        break;
      case 'delete':
        setShow(true);
        break;
    }
  };
  const onDelete = (id) => {
    props.onDelete(id);
    props.setShowModal(false);
    refetch();
  };

  const tableData = individualTrainee?.map(({ firstName, lastName, imageUrl }, index) => {
    return (
      <Box key={index}>
        <Flex justifyContent={'space-between'} alignItems={'center'} style={{ margin: '16px 0px' }}>
          <Flex gap={'0px 16px'} alignItems={'center'} textTransform={'capitalize'}>
            <Image
              src={
                imageUrl ||
                `https://api.dicebear.com/7.x/initials/svg?seed=${firstName || 'N'} ${
                  lastName || 'A'
                }`
              }
              width={'32px'}
              height={'32px'}
              borderRadius={'50%'}
            />
            <h1>
              {firstName} {lastName}
            </h1>
          </Flex>
          <Flex gap={'0px 63px'}>
            {status === 'completed' && <button className={completedButtonStyles}>{status}</button>}
            {status === 'upcoming' && <button className={upcomingButtonStyles}>{status}</button>}
            {status === 'in_progress' && (
              <button className={inProgressButtonStyles}>
                {status.substring(0, 2) + ' ' + status.substring(3)}
              </button>
            )}
            <ActionDropdown actions={dropDownForTable} onClick={actionPerform} id={props?.id} />
          </Flex>
        </Flex>
        <hr />
      </Box>
    );
  });
  return (
    <>
      <Wrapper>
        <FullModal showModal={props.showModal} noHeader mt="8.3rem">
          <header className="header">
            <ModalBackButton onClick={() => props.setShowModal(false)} />
          </header>
          <Box maxWidth={'737px'}>
            <Flex alignItems={'center'} justifyContent={'space-between'} mb={'48px'}>
              <Heading>{name}</Heading>
              <ActionDropdown actions={dropDown} onClick={actionPerform} id={props?.id} />
            </Flex>
            <Title>Training Program Details</Title>
            <Box border={'1px solid #F2F2F2'} borderRadius={'4px'} p={'24px'} marginBottom={'24px'}>
              <Grid gridTemplateColumns={'100px 1fr'} columnGap={'80px'}>
                <Box mb={'24px'}>
                  <Para>Due Date</Para>
                </Box>
                <Para>Due {dueDate ? dueDate : 'Not Available'} days after enrollment</Para>
              </Grid>
              <Grid gridTemplateColumns={'100px 1fr'} columnGap={'80px'}>
                <Box mb={'24px'}>
                  <Para>Description</Para>
                </Box>
                <Para>{description ? description : 'Not available'}</Para>
              </Grid>
              <Grid gridTemplateColumns={'100px 1fr'} columnGap={'80px'}>
                <Box mb={'24px'}>
                  <Para>Attached Files:</Para>
                </Box>
                <Para>{attachedFile ? attachedFile : 'Not available'}</Para>
              </Grid>
              <Grid
                gridTemplateColumns={'100px 1fr'}
                columnGap={'80px'}
                textTransform={'capitalize'}>
                <Box mb={'24px'}>
                  <Para>Record:</Para>
                </Box>
                <Para>{details ? details : 'Not available'}</Para>
              </Grid>
              <Grid
                gridTemplateColumns={'100px 1fr'}
                columnGap={'80px'}
                textTransform={'capitalize'}>
                <Box>
                  <Para>Program Repeat:</Para>
                </Box>
                <Para>
                  {repeatProgram?.isRepeated
                    ? `${repeatProgram?.repeatIn} ${repeatProgram?.repeatType}`
                    : 'Not available'}
                </Para>
              </Grid>
              <hr style={{ marginTop: '24px' }} />
            </Box>
            {individualTrainee?.length > 0 ? (
              <Box>
                <Flex justifyContent={'space-between'}>
                  <Title>Enrolled Employees</Title>
                  <Flex gap={'0px 75.67px'}>
                    <h1>1-2 of 2</h1>
                    <Flex gap={'0px 19px'}>
                      <h1>&lt;</h1>
                      <h1>&gt;</h1>
                    </Flex>
                  </Flex>
                </Flex>
                {tableData}
              </Box>
            ) : (
              <EmptyState message={'No employees found'} />
            )}
          </Box>
        </FullModal>
      </Wrapper>
      {showRecord && (
        <SimpleModal showModal={showRecord} padding="2.4rem" width={'75rem'}>
          <RecordPrompt cancel={() => setShowRecord(false)} id={props?.id} />
        </SimpleModal>
      )}
      {show && (
        <DeletePromptModal
          show={show}
          cancel={() => setShow(false)}
          deleteItem={() => onDelete(props?.id)}
          loading={props.deleting}
        />
      )}
    </>
  );
}
const Wrapper = styled.div`
  .buttonStyle {
    position: sticky;
    z-index: 20;
    top: 0;
    padding: 2.4rem 0;
  }
  .header {
    position: sticky;
    z-index: 20;
    top: 0;
    padding: 2.4rem 0;
    display: grid;
    grid: 1fr / max-content max-content auto;
    gap: 2.4rem;
    align-items: center;
    background-color: var(--white);
    &-text {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.05em;
    }
  }
`;
const Title = styled.h1`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0012em;
  color: #1a1a1a;
  margin-bottom: 8px;
`;
const Para = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.0012em;
  color: #83889d;
`;
const buttonStyles = css`
  border-radius: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  padding: 8px 16px;
  text-transform: capitalize;
`;
const completedButtonStyles = css`
  ${buttonStyles};
  background: rgba(54, 202, 139, 0.1);
  border: 1px solid #36ca8b;
`;
const upcomingButtonStyles = css`
  ${buttonStyles};
  background: rgba(153, 159, 163, 0.1);
  border: 1px solid #999fa3;
`;
const inProgressButtonStyles = css`
  ${buttonStyles};
  background: rgba(240, 168, 0, 0.1);
  border: 1px solid #f0a800;
  border-radius: 20px;
`;
