import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { formatDate } from 'utils';

import phoneIcon from '../../assets/icons/Call.svg';
import messageIcon from '../../assets/icons/Message.svg';
import { StatusType2 } from '../../pages/admin/employee/styles';
import { EmptyState, SpinnerComponent } from '../common';
import { CheckboxWrapper, CustomCheckbox } from '../shared/Checkbox';
import { EventsPopupAction } from '../shared/DropDownAction';

import { GridCardItem, GridContainer } from './styles';

const Status = ({ hire }) => {
  return (
    <StatusType2 status={hire}>
      <Text fontSize="10px" textTransform={'capitalize'}>
        {hire}
      </Text>
    </StatusType2>
  );
};

export const GridView = ({
  loading,
  error,
  searchLoading,
  data,
  emails,
  ids,
  checkedBool,
  handleChecked,
  actionOnData,
  dropdownactions,
  empty,
  hideCheckbox,
}) => {
  const dropdownActions = dropdownactions || ['View', 'Send Message', 'Suspended', 'Resign'];
  if (loading || error || searchLoading) {
    return <SpinnerComponent h="60vh" />;
  }
  return (
    <>
      {empty ? (
        <EmptyState />
      ) : (
        <GridContainer>
          {data?.map((details, i) => (
            <GridCardItem key={details._id}>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>
                  {hideCheckbox ? null : (
                    <CheckboxWrapper>
                      <CustomCheckbox
                        data-testid={'checked-table-rows'}
                        handleChecked={() => handleChecked(emails[i])}
                        checked={checkedBool[i]}
                        variant={'green-checkbox'}
                      />
                    </CheckboxWrapper>
                  )}
                </Box>
                <Flex alignItems="center">
                  <Status hire={details.status === 'pending' ? 'Unverified' : details.status} />
                  <Box ml="17px">
                    <EventsPopupAction
                      dropdownActions={dropdownActions}
                      actionOnData={actionOnData}
                      email={emails[i]}
                      id={ids[i]}
                    />
                  </Box>
                </Flex>
              </Flex>
              <div style={{ textAlign: 'center' }}>
                <figure
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '1.6rem 0.8rem',
                  }}>
                  <Image
                    width="72px"
                    height="72px"
                    borderRadius="50%"
                    src={
                      details.imageUrl ||
                      `https://api.dicebear.com/7.x/initials/svg?seed=${details.firstName} ${details.lastName}`
                    }
                    alt=""
                  />
                </figure>
                <Text
                  lineHeight="1.4rem"
                  color="#000000"
                  fontWeight="700"
                  fontSize="1.4rem"
                  textTransform={'capitalize'}>
                  {details.firstName} {details.lastName}
                </Text>
                <Text
                  fontSize="1.2rem"
                  textTransform={'capitalize'}
                  marginTop="0.4rem"
                  color="#BCBFCA">
                  {details?.department?.name || 'Product Designer'}
                </Text>
              </div>
              <Box
                p="0.8rem"
                backgroundColor="#FAFCFF"
                mt="1.2rem"
                border="1px solid #F2F2F2"
                borderRadius={'0.4rem'}>
                <Flex
                  justifyContent="space-between"
                  borderBottom="1px"
                  borderBottomColor="#F2F2F2"
                  pb="0.8rem">
                  <Box>
                    <Text fontSize="0.8rem" color="#BCBFCA">
                      Department
                    </Text>
                    <Text
                      fontSize="1rem"
                      fontWeight="500"
                      color="#000000"
                      textTransform={'capitalize'}>
                      {details?.department?.name}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="0.8rem" color="#BCBFCA">
                      Date Hired
                    </Text>
                    <Text fontSize="1rem" color="#000000">
                      {formatDate(details?.employmentInfo?.hireDate) || 'Date'}
                    </Text>
                  </Box>
                </Flex>
                <Box mt="1rem">
                  <Flex gap="0.9rem">
                    <img src={messageIcon} alt="" />
                    <Text
                      textDecoration="underline"
                      textDecorationColor="grey"
                      fontSize="10px"
                      color="#23273B"
                      fontWeight="500">
                      {details.email}
                    </Text>
                  </Flex>
                  <Flex gap="0.9rem" mt="0.8rem">
                    <img src={phoneIcon} alt="" />
                    <Text fontSize="10px" color="#23273B" fontWeight="500">
                      {details.phoneNumber}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            </GridCardItem>
          ))}
        </GridContainer>
      )}
    </>
  );
};
