import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { DELETE_GOAL } from 'query';

import { EmptyState, SpinnerComponent, TableComponent } from 'components';
import { Status } from 'components/tables/TableConfig';

// import image from '../../../../../assets/icons/Calendar.svg';
import { EditGoals } from '../edit-goals';
import { ViewGoals } from '../view-goals';

import { Columns } from './TableData';
const Wrapper = styled.div`
  overflow: auto;
  thead th {
    background: #f3f4f6;
  }
  thead th:first-of-type,
  tbody td:first-of-type {
    padding-left: 2.4rem;
  }
`;

const notStarted = {
  color: 'var(--black)',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: 'rgba(62, 126, 238, 0.1)',
  border: '1px solid #3E7EEE',
  borderRadius: '20px',
  textTransform: 'capitalize',
};

const upcoming = {
  color: 'var(--black)',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: ' rgba(240, 168, 0, 0.1)',
  border: '1px solid #F0A800',
  borderRadius: ' 20px',
  textTransform: 'capitalize',
};

const overdue = {
  color: 'var(--black)',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: 'rgba(250, 74, 74, 0.1)',
  border: '1px solid #FA4A4A',
  borderRadius: ' 20px',
  textTransform: 'capitalize',
};

const InProgress = ({ status }) => {
  return (
    <div style={{ ...notStarted }}>
      <p>{status.replace('_', ' ')}</p>
    </div>
  );
};

const Upcoming = ({ status }) => {
  return (
    <div style={{ ...upcoming }}>
      <p>{status}</p>
    </div>
  );
};

const Overdue = ({ status }) => {
  return (
    <div style={{ ...overdue }}>
      <p>{status}</p>
    </div>
  );
};

const GoalsTable = ({ goalData, loading, refetch }) => {
  const { successToast, errorToast } = useToastify();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [removeGoal, { loading: deleting }] = useMutation(DELETE_GOAL);
  const [sortBy, setSortBy] = useState('');
  const [tData, setTData] = useState([]);
  const [id, setId] = useState('');

  const keys = {
    Title: 'title',
    Creator: 'creator',
    Type: 'type',
    'Due Date': 'dueDate',
    Status: 'status',
  };

  const handleMultiSortBy = (column, desc = false) => {
    setSortBy(column + desc);
    const key = keys[column];
    const sortedData = tData?.map((e) => {
      const new_creator =
        typeof e?.creator === 'string'
          ? e?.creator
          : `${e?.creator?.firstName}|${e?.creator?.lastName}`;
      return {
        ...e,
        title: e?.title,
        creator: new_creator,
        type: e?.type || '',
        dueDate: e?.schedule?.dueDate || '',
        status: e?.status || '',
      };
    });
    if (key) {
      sortedData?.sort((a, b) => {
        return desc ? b[key]?.localeCompare(a[key]) : a[key]?.localeCompare(b[key]);
      });

      setTData(sortedData);
    }
  };

  function ActionsField({ data }) {
    const name = `${data?.firstName || 'N/'} ${data?.lastName || 'A'}`;
    return (
      <Flex className="imageWrapper">
        <img
          src={
            data?.imageUrl ||
            `https://api.dicebear.com/7.x/initials/svg?seed=${data?.firstName || 'N'} ${
              data?.lastName || 'A'
            }`
          }
          alt="i"
        />
        <Text textTransform={'capitalize'}>{name}</Text>
      </Flex>
    );
  }

  useEffect(() => {
    setTData(goalData);
  }, [goalData]);

  if (loading) return <SpinnerComponent />;

  const information = tData?.map(({ title, type, creator, schedule, status }) => {
    const new_creator =
      typeof creator === 'string'
        ? { firstName: creator?.split('|')[0], lastName: creator?.split('|')[1] }
        : creator;

    return {
      col1: title,
      col2: <ActionsField data={new_creator} />,
      col3: <Text textTransform={'capitalize'}>{type}</Text>,
      col4: schedule?.dueDate,
      col5:
        status === 'in_progress'
          ? InProgress({ status })
          : status === 'upcoming'
          ? Upcoming({ status })
          : status === 'overdue'
          ? Overdue({ status })
          : Status({ status }) || '',
    };
  });

  const dropdownActions = {
    edit: 'Edit Details',
    view: 'View Details',
    del: 'Delete',
  };

  const onDelete = async (id) => {
    try {
      const res = await removeGoal({
        variables: {
          goalId: id,
        },
      });
      const { data } = res;
      if (data && data.removeGoal.status === 200) {
        refetch();
        setShowModal(false);
        successToast('Goal deleted sucessfully');
      } else errorToast(data.removeGoal.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const onEdit = (id) => {
    setId(id);
    setShowEditModal(true);
    setShowModal(false);
  };

  const onView = (id) => {
    setId(id);
    setShowModal(true);
  };

  const actOnData = (payload) => {
    switch (payload?.action) {
      case 'edit-details':
        onEdit(payload?.id);
        break;
      case 'view-details':
        onView(payload?.id);
        break;
      case 'delete':
        onDelete(payload?.id);
        break;
    }
  };

  return (
    <Wrapper>
      {tData?.length ? (
        <TableComponent
          noCheckbox
          data={information}
          // exclude={'Creator'}
          columns={Columns}
          key={sortBy}
          ids={tData?.map((e) => e?._id)}
          dropdownActions={dropdownActions}
          actionOnData={actOnData}
          handleMultiSortBy={handleMultiSortBy}
        />
      ) : (
        <EmptyState message={'No Goals Found'} />
      )}
      {showModal && id ? (
        <ViewGoals
          showModal={showModal}
          setShowModal={setShowModal}
          id={id}
          deleting={deleting}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ) : null}
      {showEditModal && id ? (
        <EditGoals showModal={showEditModal} setShowModal={setShowEditModal} id={id} />
      ) : null}
    </Wrapper>
  );
};

export default GoalsTable;
