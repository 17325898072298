import React from 'react';

const MenuContext = React.createContext({ isOpen: false });

function MenuContextProvider(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [authRoute, setAuthRoute] = React.useState('/login');

  return (
    <MenuContext.Provider value={{ isOpen, setIsOpen, authRoute, setAuthRoute }}>
      {props.children}
    </MenuContext.Provider>
  );
}

function useMenuContext() {
  const context = React.useContext(MenuContext);
  if (!context) throw Error('useMenuContext must be wrapped in MenuContextProvider');
  return context;
}

export { MenuContextProvider, useMenuContext };
