import React from 'react';
import { Flex, Image, Text } from '@chakra-ui/react';

export function NameWithImage({ data }) {
  if (data == undefined) return <Text>N/A</Text>;

  function joinNames(data) {
    return `${data?.firstName} ${data?.lastName}`;
  }

  function setImage(firstName, lastName, image) {
    let imageUrl = `https://api.dicebear.com/7.x/initials/svg?seed=${firstName} ${lastName}`;
    if (image !== '') {
      imageUrl = image;
    }
    return imageUrl;
  }

  return (
    <Flex gap={'16px'} alignItems={'center'}>
      <Image
        src={setImage(data?.firstName, data?.lastName, data?.imageUrl || '')}
        width={'32px'}
        height={'32px'}
        borderRadius={'50%'}
      />
      <Text textTransform={'capitalize'}> {joinNames(data)}</Text>
    </Flex>
  );
}
