import React from 'react';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

function ActionDropdown(props) {
  const { actionList = [], id, name } = props;
  return (
    <Menu>
      <MenuButton display="grid" placeContent="center" h="4.4rem" w="2.4rem">
        <svg
          width="3"
          height="12"
          viewBox="0 0 3 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.33333 0C0.6 0 0 0.6 0 1.33333C0 2.06667 0.6 2.66667 1.33333 2.66667C2.06667 2.66667 2.66667 2.06667 2.66667 1.33333C2.66667 0.6 2.06667 0 1.33333 0ZM1.33333 9.33333C0.6 9.33333 0 9.93333 0 10.6667C0 11.4 0.6 12 1.33333 12C2.06667 12 2.66667 11.4 2.66667 10.6667C2.66667 9.93333 2.06667 9.33333 1.33333 9.33333ZM1.33333 4.66667C0.6 4.66667 0 5.26667 0 6C0 6.73333 0.6 7.33333 1.33333 7.33333C2.06667 7.33333 2.66667 6.73333 2.66667 6C2.66667 5.26667 2.06667 4.66667 1.33333 4.66667Z"
            fill="#0D0F11"
          />
        </svg>
      </MenuButton>
      <MenuList>
        {actionList.map((action) => (
          <MenuItem
            key={action.name}
            onClick={() => action.method(id)}
            data-testid={(action.name + ' ' + name)?.replace(/\s/g, '-').toLowerCase()}>
            {action.display}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export { ActionDropdown };
