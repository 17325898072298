import React from 'react';
import { CgMenuRightAlt } from 'react-icons/cg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import jwt_decode from 'jwt-decode';
import { FETCH_EMPLOYEE_DETAILS } from 'pages/employee/dashboard/query';
import { getYears, logout } from 'utils';

import ExitIcon from '../../assets/icons/Logout.svg';
import aniversary from '../../assets/images/aniversary_confetti.png';
import cake from '../../assets/images/birthday-cake.png';
import calendar from '../../assets/images/calendar.png';
import confetti from '../../assets/images/confetti.png';
import banner from '../../assets/images/welcome-board.png';
import { useMenuContext } from '../../context';

import NotificationIcon from './Notification';
import { EventPopUp } from '.';

const Navbar = () => {
  const [dropDown, setDropDown] = React.useState(false);
  const { setIsOpen, isOpen } = useMenuContext();
  const [user, setUser] = React.useState({});

  const name = user?.firstName?.trim();
  let years = getYears(user?.employmentInfo?.hireDate);

  const [showBirthdayModal, setShowBirthdayModal] = React.useState(false);
  const [showAniversaryModal, setShowAniversaryModal] = React.useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = React.useState(false);

  const location = useLocation();
  // const checkloc = location?.pathname?.includes('/employee');
  const token = localStorage.getItem('token');
  const userDetails = jwt_decode(token);
  const { data } = useQuery(FETCH_EMPLOYEE_DETAILS, {
    variables: {
      filters: {
        _id: userDetails?.id,
      },
    },
  });

  const getUser = () => {
    setUser(data?.fetchUsersFiltered?.data[0]);
  };

  React.useEffect(() => {
    getUser();
  }, [data]);

  return (
    <>
      <EventPopUp
        showModal={showBirthdayModal}
        setShowModal={setShowBirthdayModal}
        confetti={confetti}
        popUpPicture={cake}
        employeeDate={user?.dob}
        type={'birthday'}
        heading={`Hey ${name}, Happy Birthday!🥳❤️`}
        text={`Wishing you a day filled with joy, laughter, and wonderful moments. Your hard work and
            dedication contribute so much to our team, and we're grateful to have you with this
            year bring you success, good health, and all the happiness you deserve.`}
      />
      {years >= 1 ? (
        <EventPopUp
          showModal={showAniversaryModal}
          setShowModal={setShowAniversaryModal}
          confetti={aniversary}
          popUpPicture={calendar}
          years={years}
          type={'anniversary'}
          employeeDate={user?.employmentInfo?.hireDate}
          heading={`Happy Anniversary ${name}!🥳`}
          text={`Happy Work Anniversary! 🎉 Today marks ${
            years > 1 ? years + ' years' : years + ' year'
          } since you joined our team, and we couldn't be more thrilled to commemorate this significant milestone with you and we're excited to see what the future holds as we continue this journey together.`}
        />
      ) : (
        <EventPopUp
          className={'anniversary'}
          showModal={showWelcomeModal}
          setShowModal={setShowWelcomeModal}
          confetti={confetti}
          popUpPicture={banner}
          years={years}
          type={'firstTime'}
          employeeDate={user?.employmentInfo?.hireDate}
          heading={`Welcome To HRMS!🥳🎉`}
          text={`We're thrilled to have you on board! 
        Congratulations on signing in for the first time. You're now part of our growing community of users. Happy exploring!`}
        />
      )}
      <NavBar data-testid="navbar">
        <Flex className="nav__content">
          <Flex className="notification">{<NotificationIcon />}</Flex>
          <Flex className="profile__details">
            <UserImage
              firstName={user?.firstName}
              lastName={user?.lastName}
              imageUrl={user?.imageUrl}
            />
            <Text className={'user-name'}>
              {user?.firstName || ''} {user?.lastName || ''}
            </Text>
          </Flex>
          <Box className={'menu'}>
            <button className={'btn-default'} onClick={() => setDropDown((v) => !v)}>
              <UserImage
                firstName={user?.firstName}
                lastName={user?.lastName}
                imageUrl={user?.imageUrl}
              />
            </button>
            {dropDown && (
              <Box className={'drop-down'}>
                <Box className="drop-down__item">
                  <LogoutDetail mobile={'logout-mobile'} location={location} user={user} />
                </Box>
              </Box>
            )}
          </Box>
          <LogoutDetail location={location} />
          <Flex className="hamburger" onClick={() => setIsOpen(!isOpen)} user={user}>
            <CgMenuRightAlt size={24} />
          </Flex>
        </Flex>
      </NavBar>
    </>
  );
};

const UserImage = ({ firstName, lastName, imageUrl }) => {
  return (
    <UserImageWrapper width="3.2rem" height="3.2rem" as={'span'} borderRadius={'50%'}>
      <img
        src={imageUrl || `https://api.dicebear.com/7.x/initials/svg?seed=${firstName} ${lastName}`}
        alt="profile picture"
      />
    </UserImageWrapper>
  );
};
const LogoutDetail = ({ mobile, location, user }) => {
  const { authRoute } = useMenuContext();
  const navigate = useNavigate();

  const checkStatus = location?.pathname?.includes('/employee');
  const checkUser = user?.isDefaultAdmin;

  const logoutUser = () => {
    logout();
    checkStatus && !checkUser ? navigate('/login') : navigate(authRoute);
  };

  return (
    <Flex
      data-testid={'nav-logout'}
      className={`logout__wrap ${mobile}`}
      onClick={() => logoutUser()}>
      <Box className="logout-icon">
        <img src={ExitIcon} alt="i" />
      </Box>
      <Text>Logout</Text>
    </Flex>
  );
};

const NavBar = styled(Box)`
  position: sticky;
  z-index: 50;
  top: 0;
  height: 6rem;
  display: flex;
  justify-content: flex-end;
  padding-right: 3rem;
  background-color: #ffffff;
  box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.02);

  .nav__content {
    height: 100%;
    justify-self: flex-end;
    align-items: center;
  }

  .user-name {
    font-size: 1.5rem;
    font-weight: 400;
    margin-left: 0.8rem;
    color: #83889d;
  }

  .profile__details {
    display: none;
    align-items: center;
    cursor: pointer;
    border-left: 2px solid #f2f2f2;
    border-right: 2px solid #f2f2f2;
    padding: 0 1.6rem;
    margin-right: 1.6rem;
    @media (min-width: 768px) {
      display: flex;
    }
  }

  .logout__wrap {
    display: none;
    cursor: pointer;
    align-items: center;

    p {
      color: #83889d;
      margin-left: 1.4rem;
    }

    @media (min-width: 768px) {
      display: flex !important;
    }
  }

  .logout-mobile {
    display: flex;
  }

  .notification {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 1.2rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
      margin-right: 3.2rem;
    }
  }

  .btn-default {
    height: 100%;

    img {
      width: 4.2rem;
      height: 4.2rem;
    }
  }

  .hamburger {
    display: flex;
    cursor: pointer;
    @media (min-width: 768px) {
      display: none;
    }
  }

  .menu {
    display: flex;
    position: relative;
    margin-right: 1.4rem;
    @media (min-width: 768px) {
      display: none;
    }

    .drop-down {
      border: 1px solid #f2f2f2;
      position: absolute;
      top: 5rem;
      right: 1rem;
      z-index: 100;
      padding: 1.2rem;
      background: #ffffff;
      border-radius: 0.4rem;

      &__item {
        padding: 0.8rem;
        min-width: 10rem;
        display: flex;
        justify-content: center;
        cursor: pointer;

        :hover {
          background: rgba(62, 126, 238, 0.1);
        }
      }
    }
  }

  .chakra-menu__menu-button {
    display: flex;
    background: none;
    opacity: 1;
    padding: 0 1.2rem;

    :hover {
      background: transparent;
    }

    @media (min-width: 768px) {
      display: none;
    }
  }

  .css-1n744pq {
    img {
      border-radius: 50%;
    }
  }
`;

const UserImageWrapper = styled(Box)`
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export { Navbar };
