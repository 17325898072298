import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { FETCH_DASHBOARD_SUMMARY } from '../../../../query';
import { EmptyState, SpinnerComponent } from '../../../common';
import {
  EmployeeCardItem,
  EmployeesCardContainer,
  EmployeesCardText,
  NavigateBtn,
  Stroke,
} from '../styles';

export const EmployeesCard = () => {
  let navigate = useNavigate();
  const { data, loading } = useQuery(FETCH_DASHBOARD_SUMMARY);
  if (loading) return <SpinnerComponent />;

  const departments = data?.fetchDashboardSummary?.data?.employees
    .filter((dep) => dep.department)
    .map((dep) => {
      return {
        ...dep,
        total: dep.members.length,
        avatars: dep.members.map((m) => m.avatar),
        fullNames: dep.members.map((m) => m.fullName),
      };
    });

  return (
    <EmployeesCardContainer data-testid="employee-card-container">
      <EmployeesCardText>
        <p className="card-heading" data-testid="employee-card-text">
          Employees
        </p>
        <NavigateBtn data-testid="navigate-btn" onClick={() => navigate('employee')}>
          View all
        </NavigateBtn>
      </EmployeesCardText>
      <Stroke></Stroke>
      <div className="card-item-container" data-testid="employee-cardItem-container">
        {!departments.length ? <EmptyState h="20rem" message="No employees found" /> : null}
        {departments.slice(0, 4).map((department) => (
          <EmployeeCardItem key={department.department} data-testid="employee-card-item">
            <div className="card-item-text">
              <p className="departments">{department.department}</p>
              <p className="members">
                Total members: <span>{department.total}</span>
              </p>
            </div>
            <div className="card-images-container">
              {department?.avatars.slice(0, 5).map((avatar, i) => (
                <img
                  key={i}
                  title={department.fullNames[i]}
                  src={
                    avatar === null
                      ? `https://api.dicebear.com/7.x/initials/svg?seed=${department.fullNames[i]}`
                      : avatar
                  }
                  alt=""
                />
              ))}
            </div>
          </EmployeeCardItem>
        ))}
      </div>
    </EmployeesCardContainer>
  );
};
