import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { EmployeeAuth } from 'layouts';
import { resetPassword } from 'query/employee/auth';

import { BaseInput, CustomButton } from 'components';
import { AuthSidebar } from 'components/auth/Login';

export const Reset = () => {
  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = React.useState(false);
  const { successToast, errorToast } = useToastify();
  const navigate = useNavigate();
  const login = () => {
    navigate('/employee/login');
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await resetPassword(data);
      successToast(response?.message);
      reset({ email: '' });
    } catch (error) {
      errorToast(error?.response?.data?.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <ResetRoot>
        <AuthSidebar />
        <Box className="reset-content">
          <div className="reset-heading">
            <h2>Reset Password</h2>
            <p>Enter the registered email associated with your account to rest password</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BaseInput
              {...register('email', { required: true })}
              label="Email Address"
              type="email"
              name="email"
              placeholder="Enter your email"
            />
            <Flex justifyContent="space-between" alignItems="center" marginTop="24px">
              <p onClick={login} className="back-to-login">
                Back to Login
              </p>
              <CustomButton name="Reset" mW="240px" type="submit" loading={loading} />
            </Flex>
          </form>
        </Box>
      </ResetRoot>
    </>
  );
};

export const EmployeeReset = () => {
  return (
    <EmployeeAuth>
      <Reset />
    </EmployeeAuth>
  );
};

const ResetRoot = styled(Grid)`
  grid-template-columns: 1fr;
  overflow: hidden;
  place-content: center;
  height: 100vh;
  @media (min-width: 980px) {
    grid-template-columns: 1fr minmax(54rem, 1fr);
    height: 100vh;
  }
  .auth-sidebar {
    display: none;
    @media (min-width: 980px) {
      display: block;
    }
  }

  .css-1lekzkb {
    margin-top: 48px;
  }

  .css-4302v8 {
    margin-top: 10px;
  }
  .back-to-login {
    cursor: pointer;
  }

  .reset-content {
    width: 100%;
    max-width: 42rem;
    height: auto;
    margin: auto;
    padding: 0 1rem;
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.117188px;
      color: #333758;
      text-align: center;
      margin-bottom: 4.6rem;
    }

    .reset-heading {
      margin-bottom: 4.8rem;

      h2 {
        margin-bottom: 1.6rem;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.117188px;
        color: #85879b;
      }
    }
  }
`;
