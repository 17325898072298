import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';

import { FallBackComponent } from 'components/error-boundary/FallBackComponent';

import { Navbar, Sidebar } from '../components';

function AdminDashboard() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(true);

  console.log('isMenu');

  return (
    <LayoutRoot>
      <div className="left-panel">
        <Sidebar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      </div>
      <div className="right-panel">
        <Navbar />
        <ErrorBoundary FallbackComponent={FallBackComponent}>
          <Outlet />
        </ErrorBoundary>
      </div>
    </LayoutRoot>
  );
}

function LayoutRoot(props) {
  const Root = styled.div`
    height: 100vh;
    width: 100%;
    display: grid;
    grid: 100% / max-content auto;
    background: var(--bg);

    .left-panel,
    .right-panel {
      height: 100%;
    }

    .right-panel {
      width: 100%;
      overflow-y: auto;
    }
  `;

  return <Root>{props.children}</Root>;
}

export { AdminDashboard };
