import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useToastify } from 'hooks/useToastify';
import { FETCH_APPLICANT_APPLICATION, SCHEDULE_INTERVIEW, STAGING } from 'query/admin/candidates';
import { UPDATE_APPLICATION_STAGE } from 'query/admin/candidates';
const isTesting = process.env.NODE_ENV === 'test';
import { useParams } from 'react-router-dom';

import { NameWithImage, SpinnerComponent, StageIndicator, TableComponent } from 'components';

import { CandidateInfo } from '../..';
import { ScheduleInterview } from '../Modals/ScheduleInterviewModal';
import { StarRating } from '../StarRating';

import { CandidateTableColumns } from './TableCols';

const keys = {
  Applicants: 'firstName',
  Ratings: 'applicationRating',
  Status: 'status',
  Applied: 'createdAt',
};

export function CandidateTable(props) {
  const { checked, setChecked, search, data, page, loading: load } = props;
  const { successToast, errorToast } = useToastify();
  const [state, setState] = useState(null);
  const [sortBy, setSortBy] = useState('');
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openCandidateModal, setOpenCandidateModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [applicantId, setApplicantId] = useState(null);
  const [singleUserId, setSingleUserId] = useState(null);
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const [scheduleInterview, { loading }] = useMutation(SCHEDULE_INTERVIEW);
  const { id: paramId } = useParams();

  const { data: stages } = useQuery(STAGING, {
    variables: {},
  });
  const onSubmit = async (data) => {
    const interviewsArea = data?.interviewers?.map((value) => {
      return value.value;
    });
    const dataToSend = {
      ...data,
      interviewers: [...interviewsArea],
      applicationId: applicantId,
      type: data.type.label,
      address: data.address === undefined ? '' : data.address,
      additionalNotes: data.additionalNotes === undefined ? '' : data.additionalNotes,
    };

    try {
      const response = await scheduleInterview({ variables: { data: dataToSend } });
      response?.data?.scheduleInterview?.status === 201
        ? successToast('Interview scheduled successfully')
        : errorToast(response?.data?.scheduleInterview?.message);
      setOpenModal(false);
    } catch (error) {
      errorToast('Error occured');
    } finally {
      reset();
    }
  };

  const [updateStatus] = useMutation(UPDATE_APPLICATION_STAGE, {
    refetchQueries: [
      {
        query: FETCH_APPLICANT_APPLICATION,
        variables: {
          filter: {
            jobId: paramId,
            name: search,
          },
          page,
        },
      },
    ],
  });

  function formatDate(date) {
    const dateLabel = new Date(date).toLocaleDateString('en-uk', {
      day: `2-digit`,
      month: 'long',
      year: 'numeric',
    });
    return dateLabel;
  }

  const OnScheduleInterview = (id) => {
    setId(id);
    setOpenModal(true);
  };

  function getRankId({ rank }) {
    if (rank === stages?.fetchStages?.data?.length) {
      return stages?.fetchStages?.data?.find((item) => item.rank === rank)._id;
    } else {
      return stages?.fetchStages?.data?.find((item) => item.rank === rank + 1)._id;
    }
  }

  const actionToChangeStatus = async (id, email) => {
    try {
      if (email?._id === stages?.fetchStages?.data[4]?._id) {
        errorToast('Applicant is at last stage of the Pipeline');
      } else {
        const result = await updateStatus({
          variables: {
            data: {
              applicationId: id,
              stageId: getRankId(email),
            },
          },
        });
        if (result.data.updateApplicationStage.status === 200) {
          successToast('Status updated successfully');
        }
      }
    } catch (err) {
      errorToast(err.message);
    }
  };

  const actOnData = (payload) => {
    switch (payload?.action) {
      case 'schedule-interview':
        setApplicantId(payload?.id);
        OnScheduleInterview(payload?.id);
        setOpenModal(true);
        break;
      case 'move-next-status':
        actionToChangeStatus(payload?.id, payload?.email);
        break;
      case 'email-candidate':
        window.location = `mailto:${payload?.status}`;
        break;
      case 'view-candidate':
        setOpenCandidateModal(true);
        setSingleUserId(payload?.id);
        break;
    }
  };

  const dropdownActions = [
    'View Candidate',
    'Move Next Status',
    'Schedule Interview',
    'Email Candidate',
    'Delete',
  ];
  // const dropdownActionsForCompletedPipeline = [
  //   'View Candidate',
  //   'Schedule Interview',
  //   'Email Candidate',
  //   'Delete',
  // ];

  const handleMultiSortBy = (column, desc = false) => {
    setSortBy(column + desc);
    const key = keys[column];
    const sortedData = tableData?.applicants?.map((e) => {
      return {
        ...e,
        firstName: e?.firstName,
        applicationRating: e?.applicationRating,
        status: e?.stage?.rank,
        createdAt: e?.createdAt,
      };
    });
    if (key) {
      sortedData.sort((a, b) => {
        return desc
          ? b[key].toString().localeCompare(a[key])
          : a[key].toString().localeCompare(b[key]);
      });
      const newObject = { ...data, applicants: sortedData };
      setTableData(newObject);
    }
  };

  function ActionsField({ data }) {
    const name = `${data?.firstName || 'N/'} ${data?.lastName || 'A'}`;
    return (
      <Flex className="imageWrapper">
        <img
          src={
            data?.imageUrl ||
            `https://api.dicebear.com/7.x/initials/svg?seed=${data?.firstName || 'N'} ${
              data?.lastName || 'A'
            }`
          }
          alt="i"
        />
        <Text textTransform={'capitalize'}>{name}</Text>
      </Flex>
    );
  }

  const hiringManager = tableData?.createdBy;

  const newTableData = tableData?.applicants?.map(
    ({ firstName, imageUrl, lastName, createdAt, stage, stageDoc, applicationRating }) => {
      return {
        col1: <NameWithImage data={{ firstName, lastName, imageUrl }} />,
        col2: <StarRating rating={applicationRating} />,
        col3: (
          <StageIndicator
            items={{
              value: stage?.rank === null ? stageDoc[0]?.rank : stage?.rank,
              label: stage?.title === null ? stageDoc[0]?.title : stage?.title,
            }}
            rank={stage?.rank === null ? stageDoc[0]?.rank : stage?.rank}
            stages={stages}
            isStatus
          />
        ),
        col4: `${formatDate(createdAt)}`,
        col5: <ActionsField data={hiringManager} />,
      };
    }
  );

  const handleChecked = (email, id, empStatus) => {
    setChecked((prev) =>
      checked.some((e) => e.id === id)
        ? prev.filter((cur) => cur.id !== id)
        : [...prev, { id, stage: email, email: empStatus }]
    );
  };
  const checkedBool = tableData?.applicants?.map((emp) => {
    return checked.some((e) => e.id === emp._id);
  });
  const handleSelectAll = (e) => {
    const { checked: isChecked } = e.target;
    if (!isChecked) {
      return setChecked([]);
    }
    let checkedCopy = Array(tableData?.applicants?.length);
    tableData?.applicants?.forEach((elem, i) => {
      checkedCopy[i] = { id: elem._id, stage: elem.stage, email: elem.email };
    });
    setChecked(checkedCopy);
  };

  useEffect(() => {
    watch('type')?.value === 'Physical' ? setState('Physical') : setState('Online');
    handleSelectAll({ target: { checked: selectAll } });
    const fetchApplicantApplications = data;
    setTableData(fetchApplicantApplications);
  }, [selectAll, data, watch('type'), search]);

  if (data === undefined && !isTesting)
    return (
      <Box height={'40vh'}>
        <SpinnerComponent />
      </Box>
    );

  return (
    <>
      <Box overflow={'scroll'}>
        <TableComponent
          minH={'450px'}
          checked={checked}
          loading={load}
          checkedBool={checkedBool}
          handleChecked={handleChecked}
          // handleSelectAll={handleSelectAll}
          setAllChecked={setSelectAll}
          allChecked={selectAll}
          key={sortBy}
          exclude={'Hiring Manager'}
          data={newTableData}
          columns={CandidateTableColumns}
          actionOnData={actOnData}
          emails={tableData?.applicants?.map(({ stage }) => stage || 1)}
          ids={tableData?.applicants?.map((e) => e._id)}
          empStatus={tableData?.applicants?.map((e) => e.email)}
          handleMultiSortBy={handleMultiSortBy}
          empty={tableData?.applicants?.length === 0}
          dropdownActions={dropdownActions}
        />
      </Box>
      <ScheduleInterview
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        control={control}
        state={state}
        id={id}
        loading={loading}
      />
      <CandidateInfo
        openModal={openCandidateModal}
        userId={singleUserId}
        stages={stages}
        setOpenModal={setOpenCandidateModal}
      />
    </>
  );
}
