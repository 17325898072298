/* eslint-disable no-unused-vars */
import React from 'react';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { Box, Button, Grid, Image, Textarea } from '@chakra-ui/react';
import styled from '@emotion/styled';
import {
  FETCH_MESSAGES,
  SUBSCRIBE_TO_MESSAGES,
  UPDATE_UNREAD_MESSAGES,
  USER_TYPING_SUBSCRIPTION,
} from 'query';
import { getItem } from 'utils';

import { getFrom } from '../helpers';

import { ChatControl } from './ChatControl';
import { getChatName, Time } from './ChatDetail';
import { ChatMessageList } from './ChatMessageList';
import { EmojiMenu } from './EmojiMenu';

export function ActiveChat(props) {
  const { active, activeChat } = props;

  const [messages, setMessages] = React.useState(() => []);
  const [page, setPage] = React.useState(() => 1);
  const loading = React.useRef(true);
  const [first, setFirst] = React.useState(true); // to only scroll to last on first render
  const [fetchMessages, { data: lazyData, refetch, loading: fetchingMessages }] = useLazyQuery(
    FETCH_MESSAGES,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const [updateUnread] = useMutation(UPDATE_UNREAD_MESSAGES);
  const userDetails = getItem('hrm_user');

  const receipientId = userDetails?._id;
  const { data } = useSubscription(SUBSCRIBE_TO_MESSAGES, {
    variables: {
      receipientId,
    },
  });

  async function updateUnreadMessages() {
    await updateUnread({ variables: { data: { roomId: active } } });
    props.setRefetch((p) => !p);
  }

  async function getMessages() {
    const res = await fetchMessages({
      variables: {
        filter: {
          roomId: props.active,
          page,
        },
      },
    });

    setMessages((prev) => {
      const messageIds = prev.map((m) => m._id);
      const newList = res.data?.fetchMessages?.data?.messages.filter(
        (m) => !messageIds.includes(m._id)
      );
      (newList || []).reverse();
      return [...(newList || []), ...prev];
    });
  }

  async function getMessageList() {
    try {
      loading.current = true;
      await getMessages();
      await updateUnreadMessages();
    } finally {
      loading.current = false;
    }
  }

  React.useEffect(() => {
    const messageIds = messages.map((m) => m._id);
    if (
      data?.newMessage &&
      data.newMessage.author?._id !== userDetails?._id &&
      data.newMessage.author?._id === activeChat?.from?._id &&
      !messageIds.includes(data.newMessage._id)
    ) {
      setFirst(true);
      setMessages((prev) => [...prev, data.newMessage]);
      updateUnreadMessages();
    }
  }, [data]);

  React.useEffect(() => {
    getMessageList();
  }, [props.active]);

  React.useEffect(() => {
    if (!first) getMessages();
  }, [page]);

  return (
    <ActiveChatWrapper>
      <Header activeChat={activeChat} />
      <ChatMessageList
        active={active}
        activeChat={activeChat}
        messages={messages}
        loading={fetchingMessages}
        setPage={setPage}
        data={lazyData}
        first={first}
        setFirst={setFirst}
      />
      <ChatControl
        activeChat={activeChat}
        active={props.active}
        setMessages={setMessages}
        setRefetch={props.setRefetch}
        setFirst={setFirst}
      />
    </ActiveChatWrapper>
  );
}

let timeout = null;
function Header(props) {
  const name = getChatName(props.activeChat?.from || '');
  const imageUrl = !props.activeChat?.from?.imageUrl
    ? `https://api.dicebear.com/7.x/initials/svg?seed=${name}`
    : props.activeChat?.from?.imageUrl;
  const [typing, setTyping] = React.useState(false);
  const userDetails = getItem('hrm_user');

  const { data } = useSubscription(USER_TYPING_SUBSCRIPTION, {
    variables: { receipientId: userDetails?._id },
  });

  function removeTyping() {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setTyping(false);
    }, 1000);
  }

  React.useEffect(() => {
    if (data?.userTyping) {
      setTyping(true);
      removeTyping();
    }
  }, [data]);

  return (
    <Grid
      className="header"
      p="18px 24px"
      bg="white"
      minH="84px"
      border="1px solid var(--grey-150)"
      gap="16px"
      alignItems={'center'}
      autoColumns={'max-content'}
      autoFlow={'column'}>
      <Box className="image">
        <Image
          src={imageUrl}
          w="32px"
          h="32px"
          objectFit="cover"
          objectPosition="center"
          borderRadius="50%"
        />
      </Box>
      <Box className="details">
        <Box fontWeight="600" className="line-clamp-1" lineHeight="1.9rem">
          {name}
        </Box>
        {/* {typing ? (
          <Box className="status" color="brand.grey.text" fontSize="1.4rem" mt="6px">
            Typing...
          </Box>
        ) : null} */}
      </Box>
    </Grid>
  );
}

const ActiveChatWrapper = styled(Box)`
  height: 100%;
  display: grid;
  grid: max-content auto max-content / 1fr;
`;
