import { format, parseISO } from 'date-fns';

import { useToastify } from '../../../../hooks/useToastify';

const { successToast } = useToastify();

export function setImage(firstName, lastName, image) {
  let imageUrl = `https://api.dicebear.com/7.x/initials/svg?seed=${firstName} ${lastName}`;
  if (image !== '') {
    imageUrl = image;
  }
  return imageUrl;
}
export const formatDate = (value) => {
  if (value) {
    return format(parseISO(value), 'MMMM dd');
  } else {
    return null;
  }
};

export const formatDateShort = (value) => {
  const dateTime = new Date(value).toLocaleDateString('en-us', {
    //weekday: 'short',
    month: 'short',
    day: `numeric`,
  });
  return dateTime;
};

export function joinNames(data) {
  return `${data?.firstName} ${data?.lastName}`;
}

export function getAllNotificationIds(data) {
  let arr = [];
  for (let message of data) {
    if (!arr.includes(message)) {
      arr.push(message._id);
    }
  }
  return arr;
}

export let tabs = [
  { id: 1, label: 'recent', active: true },
  { id: 2, label: 'archived', active: false },
];

function activeTab(id) {
  tabs = tabs.map((item) =>
    item.id === id ? { ...item, active: true } : { ...item, active: false }
  );

  return tabs.find((el) => el.id === id).label;
}

export function switchTab(id, refetch) {
  switch (activeTab(id)) {
    case 'recent':
      refetch({
        filter: {
          status: 'recent',
        },
      });
      break;
    case 'archived':
      refetch({
        filter: {
          status: 'archived',
        },
      });
      break;

    default:
      break;
  }
}

export async function archiveMessage(id, updateNotification) {
  const res = await updateNotification({
    variables: {
      notificationId: id,
      data: {
        status: 'archived',
      },
    },
  });

  if (res?.data?.updateNotification?.status === 200) {
    successToast(res?.data?.updateNotification?.message);
  }
}

export async function unArchiveMessage(id, updateNotification) {
  const res = await updateNotification({
    variables: {
      notificationId: id,
      data: {
        status: 'recent',
      },
    },
  });

  if (res.data.updateNotification.status === 200) {
    successToast(res?.data?.updateNotification?.message);
  }
}

export function showUpcomingEvents(data) {
  return data?.length > 0 ? true : false;
}

export function getAllUpcomingEvents(data = {}) {
  let arr = [];
  if (data === null) {
    return arr;
  }
  Object.entries(data).forEach(([, value]) => {
    for (let event of value) {
      arr.push(event);
    }
  });
  return arr;
}

function alterDates(array) {
  function alterDate(string) {
    const newString = string.split('-');
    newString[0] = new Date().toISOString().split('-')[0];
    return newString.join('-');
  }

  const arr = [];
  if (array === undefined || array.length === 0) return arr;

  for (let item of array) {
    if (item.dob !== undefined) {
      let newItem = { ...item, dob: `${alterDate(item.dob)}` };
      arr.push(newItem);
    } else {
      let newItem = {
        ...item,
        employmentInfo: {
          hireDate: `${alterDate(item.employmentInfo?.hireDate)}`,
        },
      };
      arr.push(newItem);
    }
  }

  return arr;
}

export function groupInToTimes(data) {
  let obj = {};
  for (let item of data) {
    if (item.dob && !Object.keys(obj).includes(item.dob)) {
      obj[item.dob] = [item];
    } else if (item.employmentInfo && !Object.keys(obj).includes(item?.employmentInfo?.hireDate)) {
      obj[item?.employmentInfo?.hireDate] = [item];
    } else {
      if (item.dob !== undefined) {
        obj[item.dob].push(item);
      } else {
        obj[item.employmentInfo.hireDate].push(item);
      }
    }
  }
  return obj;
}

export function groupEvents(data) {
  let modData = groupInToTimes(alterDates(getAllUpcomingEvents(data)));
  return modData;
}

export function getDateLabel(date) {
  return setDateLabel(date);
}

function setDateLabel(date) {
  const dateTime = new Date(date).toLocaleDateString('en-us', {
    weekday: 'short',
    month: 'short',
    day: `numeric`,
  });
  let label = null;

  const num = dateTime.split(' ').pop();
  switch (num) {
    case `1`:
      if (setTypeofDay(date) === 'today') {
        label = `Today`;
      } else if (setTypeofDay(date) === 'tomorrow') {
        label = `Tomorrow`;
      } else {
        label = `${dateTime}st`;
      }
      break;
    case `2`:
      if (setTypeofDay(date) === 'today') {
        label = `Today`;
      } else if (setTypeofDay(date) === 'tomorrow') {
        label = `Tomorrow`;
      } else {
        label = `${dateTime}nd`;
      }
      break;
    case `3`:
      if (setTypeofDay(date) === 'today') {
        label = `Today`;
      } else if (setTypeofDay(date) === 'tomorrow') {
        label = `Tomorrow`;
      } else {
        label = `${dateTime}rd`;
      }
      break;
    case `21`:
      if (setTypeofDay(date) === 'today') {
        label = `Today`;
      } else if (setTypeofDay(date) === 'tomorrow') {
        label = `Tomorrow`;
      } else {
        label = `${dateTime}st`;
      }
      break;
    case `22`:
      if (setTypeofDay(date) === 'today') {
        label = `Today`;
      } else if (setTypeofDay(date) === 'tomorrow') {
        label = `Tomorrow`;
      } else {
        label = `${dateTime}nd`;
      }
      break;
    case `23`:
      if (setTypeofDay(date) === 'today') {
        label = `Today`;
      } else if (setTypeofDay(date) === 'tomorrow') {
        label = `Tomorrow`;
      } else {
        label = `${dateTime}rd`;
      }
      break;
    case `31`:
      if (setTypeofDay(date) === 'today') {
        label = `Today`;
      } else if (setTypeofDay(date) === 'tomorrow') {
        label = `Tomorrow`;
      } else {
        label = `${dateTime}st`;
      }
      break;

    default:
      if (setTypeofDay(date) === 'today') {
        label = `Today`;
      } else if (setTypeofDay(date) === 'tomorrow') {
        label = `Tomorrow`;
      } else {
        label = `${dateTime}th`;
      }
      break;
  }
  return label;
}

function setTypeofDay(date) {
  const today = new Date().toISOString().split('T')[0].split('-');
  const secondDate = date.split('T')[0].split('-');
  const temp1 = `${today[1]}${today[2]}`;
  const temp2 = `${secondDate[1]}${secondDate[2]}`;
  if (temp1 === temp2) {
    return 'today';
  } else if (today[0] === secondDate[0] && today[1] === secondDate[1] && temp2[3] - temp1[3] == 1) {
    return 'tomorrow';
  } else {
    return 'anyDay';
  }
}

export function getGreeting() {
  let greeting;
  const date = new Date().getHours();
  if (date >= 0 && date <= 11) {
    greeting = 'Morning';
  } else if (date >= 12 && date <= 17) {
    greeting = 'Afternoon';
  } else if (date >= 18 && date <= 23) {
    greeting = 'Evening';
  }
  return greeting;
}
