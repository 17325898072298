import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { EmptyState, SpinnerComponent } from 'components';

export const OverlapRequest = ({ overlap, loading }) => {
  if (loading) {
    return <SpinnerComponent h="30vh" />;
  }
  return (
    <Root className="overlap">
      <p className="custom-text">Overlap in Department</p>
      {overlap?.length === 0 ? (
        <EmptyState message="No Overlap In Department" w="100%" h="40vh" />
      ) : (
        <Box mt="4.8rem">
          {overlap?.map((el, i) => {
            const imageUrl = el?.employeeId?.imageUrl;
            const firstName = el?.employeeId?.firstName;
            const lastName = el?.employeeId?.lastName;
            return (
              <div className="overlap-row" key={i}>
                <img
                  src={
                    imageUrl ||
                    `https://api.dicebear.com/7.x/initials/svg?seed=${firstName} ${lastName}`
                  }
                  alt="i"
                />
                <Text textTransform={'capitalize'}>{`${firstName} ${lastName}`}</Text>
              </div>
            );
          })}
        </Box>
      )}
    </Root>
  );
};

const Root = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 2.4rem;

  .overlap-row {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding: 1.6rem;
    border-bottom: 1px solid #f2f2f2;

    img {
      border-radius: 50%;
      width: 34px;
      height: 34px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: var(--black);
    }
  }
`;
