import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const RenderImages = ({ data }) => {
  if (data?.length > 0)
    return (
      <>
        <ImageStyles>
          <Flex justify={'space-between'} alignItems={'center'}>
            <Flex className="applicant-images">
              {data?.slice(0, 3)?.map((applicant) => {
                return (
                  <div
                    className="image-wrapper"
                    key={`${applicant?.firstName}${applicant?.lastName}`}>
                    <img
                      src={
                        applicant?.imageUrl ||
                        `https://api.dicebear.com/7.x/initials/svg?seed=${applicant?.firstName} ${applicant?.lastName}`
                      }
                      alt="image"
                    />
                  </div>
                );
              })}
            </Flex>
            <p className="candidates-total">{`${data.length} candidates`}</p>
          </Flex>
        </ImageStyles>
      </>
    );
  return (
    <>
      <p>N/A</p>
    </>
  );
};

export const ApplicantCard = ({ jobs, path }) => {
  const navigate = useNavigate();

  const handleCardAction = (jobId) => {
    navigate(`${path}/${jobId}`);
  };
  return (
    jobs &&
    jobs.map((job) => (
      <Card key={job?._id} data-testid={'job-card'} onClick={() => handleCardAction(`${job?._id}`)}>
        <CardHeader>
          <p>{job?.title}</p>
        </CardHeader>
        {/* <p className="job-description">{job?.description || ' Job description N/A'}</p> */}
        <RenderImages data={job?.applicants} />
      </Card>
    ))
  );
};

const Card = styled(Box)`
  background: #fafcff;
  width: 259px;
  padding: 16px 8px;
  border-radius: 2.79364px;
  cursor: pointer;

  .job-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.0112em;
    color: #83889d;
    margin-bottom: 16px;
  }
`;
const CardHeader = styled.div`
  margin-bottom: 21px;
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #23273b;
  }
`;
const ImageStyles = styled.div`
  .image-wrapper {
    width: 24px;
    aspect-ratio: 1;
    border-radius: 50%;

    img {
      border-radius: 50%;
    }
  }

  .candidates-total {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #83889d;
  }
`;
