import { useMutation } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';
import { EDIT_EMPLOYEE, FETCH_SINGLE_EMPLOYEE_DETAILS } from 'query';

function SubmitForm(props) {
  const { closeModal, refetch } = props;

  const [editUser, { loading }] = useMutation(EDIT_EMPLOYEE);

  const { successToast, errorToast } = useToastify();

  const SubmitDetails = async ({ id, dataToSend }) => {
    try {
      const res = await editUser({
        variables: { id: id, data: dataToSend },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: FETCH_SINGLE_EMPLOYEE_DETAILS, variables: { filters: { _id: id } } },
        ],
      });

      if (res?.data?.editUser?.status === 200) {
        const message = res?.data?.editUser?.message;
        successToast(message);
      }

      closeModal ? closeModal() : null;
    } catch (error) {
      errorToast(error.message || 'Error occured');
    } finally {
      refetch ? refetch() : null;
    }
  };

  const checkTypeOf = (value) => {
    const new_val = typeof value === 'object' ? value?.value : value;
    return new_val;
  };

  const submitPersonalDetails = async (data) => {
    const date = data.dob ? new Date(data.dob) : '';
    const dob = date ? date.toISOString() : '';
    const personal = {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      location: data.location,
      dob: dob,
      gender: checkTypeOf(data.gender),
      maritalStatus: checkTypeOf(data.maritalStatus),
    };

    SubmitDetails({ id: data._id, dataToSend: personal });
  };
  const submitMedicalDetails = async (data) => {
    const medical = {
      bloodGroup: checkTypeOf(data.bloodGroup),
      bloodType: checkTypeOf(data.bloodType),
      notableAilment: data.notableAilment,
      isDisabled: checkTypeOf(data.isDisabled),
      disabilityType: data.disabilityType,
    };

    SubmitDetails({ id: data._id, dataToSend: medical });
  };
  const submitEmploymentInfo = async (data) => {
    const info = { ...data.employmentInfo };
    const hire_date = info?.hireDate ? new Date(info?.hireDate).toISOString() : '';
    const employment = {
      employmentType: checkTypeOf(info?.employmentType),
      compensationTypes: checkTypeOf(info?.compensationTypes[0]),
      hireDate: hire_date,
      monthlyPayRate: info?.monthlyPayRate,
      workEmail: info?.workEmail,
    };

    const toSend = {
      employmentInfo: employment,
      team: data.team?._id ?? data.team,
      staffId: data.staffId,
      department: checkTypeOf(data.department),
      designation: checkTypeOf(data.designation),
    };

    SubmitDetails({ id: data._id, dataToSend: toSend });
  };
  const submitPensionDetails = async (data) => {
    const info = { ...data.bankAccount };
    const pension = {
      bankAccount: {
        ...data.bankAccount,
        paymentType: checkTypeOf(info?.paymentType),
        accountType: checkTypeOf(info?.accountType),
        pensionAccountNo: info?.pensionAccountNo,
        pensionFundAdministrator: info?.pensionFundAdministrator,
      },
    };
    info?.pensionAccountNo !== ''
      ? SubmitDetails({ id: data._id, dataToSend: pension })
      : errorToast('Supply pension number ');
  };
  const submitBankDetails = async (data) => {
    const info = { ...data.bankAccount };
    const bank = {
      bankAccount: {
        ...data.bankAccount,
        paymentType: checkTypeOf(info?.paymentType),
        accountType: checkTypeOf(info?.accountType),
        bankName: info?.bankName,
        accountNo: info?.accountNo,
        accountName: info?.accountName,
      },
    };

    SubmitDetails({ id: data._id, dataToSend: bank });
  };
  const submitContactDetails = async (data) => {
    const addressToSend =
      data.address.length > 0
        ? data.address
            .filter((add) => add.address !== '')
            .map(({ country, state, ...rest }) => ({
              country: country?.value,
              state: state?.value,
              ...rest,
            }))
        : [];
    const contact = {
      address: addressToSend,
    };

    SubmitDetails({ id: data._id, dataToSend: contact });
  };
  const submitEmergencyContact = async (data) => {
    const info = { ...data.emergencyContact };
    const kin = {
      emergencyContact: {
        ...data.emergencyContact,
        country: checkTypeOf(info?.country),
        state: checkTypeOf(info?.state),
        city: checkTypeOf(info?.city),
      },
    };

    SubmitDetails({ id: data._id, dataToSend: kin });
  };

  return {
    loading,
    submitPersonalDetails,
    submitMedicalDetails,
    submitEmploymentInfo,
    submitPensionDetails,
    submitBankDetails,
    submitContactDetails,
    submitEmergencyContact,
    SubmitDetails,
  };
}

export { SubmitForm };
