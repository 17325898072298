import React from 'react';
import { Flex, Grid, Text } from '@chakra-ui/react';

import phoneIcon from '../../../assets/icons/Call.svg';
import messageIcon from '../../../assets/icons/Message.svg';

import { StatusType } from './styles';

export const UserImage = (hire) => {
  return (
    <Flex className="imageWrapper">
      <img
        src={
          hire.imageUrl ||
          `https://api.dicebear.com/7.x/initials/svg?seed=${hire.firstName} ${hire.lastName}`
        }
        alt="i"
      />
      <Text textTransform={'capitalize'}>{hire.name}</Text>
    </Flex>
  );
};
export const Contact = (hire) => {
  return (
    <Grid className="contact" gridGap={'0.8rem'}>
      <Flex>
        <img src={messageIcon} alt="i" />
        <Text ml="0.9rem">{hire.email}</Text>
      </Flex>
      <Flex>
        <img src={phoneIcon} alt="i" />
        <Text ml="0.9rem">{hire.phoneNumber}</Text>
      </Flex>
    </Grid>
  );
};

export const Status = (hire) => {
  return (
    <StatusType className="status" status={hire.statusChecked}>
      <Text textTransform={'capitalize'}>{hire.statusChecked}</Text>
    </StatusType>
  );
};
